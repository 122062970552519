import React from 'react';
import { Container } from 'react-bootstrap';
import Acnavbar from '../components/Acnavbar';
import Footer from '../components/footer';
import Seo from '../shared/seo';
import logo from '../assets/images/softobotics-logo-tab.svg';
import Wrapper from '../components/Wrapper';

function Privacypolicy() {
  return (
    <Wrapper>
      <Seo
        title='Privacy Policy: Safeguarding Your Data with Softobotics'
        description="Learn about Softobotics' commitment to protecting your privacy and how we handle your data in accordance with our privacy policy."
      />
      <div className='privacy-policy-bg'>
        <Acnavbar home={false} pathname={'/privacy-policy'} logo={logo} logoLogic={false} />
      </div>

      <Container>
        <div className='mt-8 mb-4 mb-lg-5 px-2'>
          <div className=''>
            <h1 className='fnr-lg-32 fw-700'>Privacy Policy</h1>
          </div>
          <div className='py-4 fnt-20 fnt-lg-24 fw-700 lh-lg-40'>
            Your access to and use of this site is subject to the following terms and conditions and
            all applicable laws. By accessing and using this site, you acknowledge that you have
            read and accept the terms and conditions, without limitation or qualification.
          </div>
          <div className='fnt-14 fnt-lg-16 mb-4'>
            This website is administered by Softobotics. At Softobotics (together with its
            subsidiaries and affiliates, “Softobotics” or “We”), your privacy is very important to
            us. The primary purpose of Softobotics.com is to be a dynamic resource and business tool
            to assist you in understanding Softobotics offerings and matching your needs and
            requirements with Softobotics offerings. It is also utilized as a portal for current and
            future employees to provide and access information in a secure and controlled way. We
            want you to feel secure when visiting our site and are committed to maintaining your
            privacy when doing so. This Privacy Policy (“Policy”) describes and governs our use and
            disclosure of the information we collect through our website:{' '}
            <a href=' https://www.softobotics.com'>https://www.softobotics.com</a> (the “site”), as
            well as information that we collect through other means about people who contact us,
            customers, suppliers, and prospective investors and employees (“you” or “your”) .Legal
            basis for gathering and/or processing your Information:
            <ol className='mt-4 fnt-14 fnt-lg-16'>
              <li>
                to assess the site’s effectiveness and usefulness to those who enter the site and
                search its contents.
              </li>
              <li>to allow for application to positions posted on the site.</li>
              <li>
                to periodically send promotional e-mails about our new products and service
                offerings or other information which we think you may find interesting using the
                e-mail address that you have provided.
              </li>
              <li>to allow electronic submission of required paperwork.</li>
              <li>to respond to any query or complaint that you raise with us and</li>
              <li>to maintain our internal records.</li>
            </ol>
          </div>
          <div className='fnt-18 fnt-lg-20 my-4 fw-800'>Information We Collect :</div>
          <div className='mb-4'>
            <span className='fw-800 fnt-18 fnt-lg-20'>1.Information Provided Directly to Us:</span>
            <span className='fnt-14 fnt-lg-16'>
              Usually, we do not allow users to directly provide us with their information or any
              “Personal Data” / “Sensitive Personal Information” (as may be defined under the
              applicable laws) through the site. However, you may provide us directly with
              information in order to access certain services, to provide services to us or to
              request further information of Softobotics. This information may include your name,
              contact information, resume information, payment information or other information
              about yourself.
            </span>
          </div>
          <div className='my-4'>
            <span className='fw-800 fnt-18 fnt-lg-20 '>
              2. Information Collected Indirectly or Automatically:
            </span>
            <span className='fnt-14 fnt-lg-16'>
              One example of information we collect indirectly is through our Internet access logs.
              When you access <a href=' https://www.softobotics.com'>https://www.softobotics.com</a>
              , your Internet address is automatically collected and is placed in our Internet
              access logs. Also, we may use other indirect sources such as third-party analytics
              providers and technologies, including cookies and similar tools, to assist in
              collecting similar information, including but not limited to:
            </span>
            <div className='mb-2 mt-4 fnt-14 fnt-lg-16'>
              (a) IP addresses, location information, unique device identifiers, IMEI, and other
              information about your computer or device(s), browser types, browser language,
              operating system, mobile device carrier information, and the state or country from
              which you accessed the site; and{' '}
            </div>
            <div className='my-2 fnt-14 fnt-lg-16'>
              (b) Information related to the ways in which you interact with the site, such as
              referring and exit web pages and URLs, platform type, the number of clicks, domain
              names, landing pages, pages and content viewed and the order of those pages,
              statistical information about the use of the site, the amount of time spent on
              particular pages, the date and time of use, the frequency of your use of the site,
              error logs, and other similar information.
            </div>
          </div>
          <div className='fw-800 fnt-18 fnt-lg-20 my-4'>Legitimate Use and Transfers of Data:</div>
          <div className='fnt-14 fnt-lg-16'>
            As a global organization, information and/or data we collect may be transferred
            internationally throughout Softobotics’s worldwide organization to recipients located
            outside the European Economic Area. We will ensure that all transfers take place in
            accordance with the applicable privacy and data protection laws, including by entering
            into data transfer agreements where necessary. We will not sell individual information
            and will share it only with our affiliates, subsidiaries and required service providers
            and/or clients where it is applicable and where consent has been provided by you for
            such use. If Softobotics requires Sensitive Personal Information (“Sensitive PII”) from
            you, then Softobotics will specifically ask for your consent to our proposed uses of
            such Sensitive PII. The references to “Sensitive PII” are to the various categories of
            personal data identified by the United States of America and other data privacy laws
            such as California Consumer Privacy Act “CCPA”, including other countries’ laws and
            policies such General Data Protection Regulation “GDPR” of EU law, as requiring special
            treatment, including in some circumstances the need to obtain explicit consent. These
            categories may include but are not limited to personal identity numbers, personal data
            about personality and private life, racial or ethnic origin, nationality, membership of
            a trade union or profession or trade association, physical or mental health, addictions,
            property matters, or criminal records (including information about suspected criminal
            activities).
          </div>
          <div className='fw-800 fnt-18 fnt-lg-20 my-4'>Safeguard of Your Information:</div>
          <div className='fnt-14 fnt-lg-16'>
            We take appropriate measures to maintain the security of your data on{' '}
            <a href=' https://www.softobotics.com'>https://www.softobotics.com</a>. However, you
            should understand that the open nature of the Internet is such that data may flow over
            networks without security measures and may be accessed and used by people other than
            those for whom the data is intended. As a result, while Softobotics strives to protect
            your information, we cannot and do not guarantee that these measures will be 100%
            effective to secure any information you transmit to or from the site.
          </div>

          <div className='fw-800 fnt-18 fnt-lg-20 my-4'>Retention Period:</div>
          <div className='fnt-14 fnt-lg-16'>
            We will retain your personal information for as long as needed or permitted in light of
            the purpose(s) for which it was obtained. The criteria used to determine our retention
            periods include:
            <div>
              (i) The length of time we have an ongoing relationship with you and provide the
              Service to you;
            </div>
            <div>(ii) Whether there is a legal obligation to which we are subject; and</div>
            <div>
              (iii) Whether retention is advisable in light of our legal position (such as in regard
              to applicable statutes of limitations, litigation, or regulatory investigations).
            </div>
          </div>
          <div className='fw-800 fnt-18 fnt-lg-20 my-4'>Subscribe or Unsubscribe:</div>
          <div className='fnt-14 fnt-lg-16'>
            It is completely up to you whether or not you want to access and use this site and
            provide the information requested, if applicable (generally termed as, “Subscribe”).
            Softobotics uses the contact information you provide to us via Let’s Connect Forms to
            contact you about our products and services. You may unsubscribe from these
            communications at any time by clicking the (“Unsubscribe”) link in any email that we
            send to you.
          </div>
          <div className='fw-800 fnt-lg-20 my-3'>Questions About this Policy?</div>
          <div className=' fnt-14 fnt-lg-16'>
            If you have any questions about our Policy, please contact us using the information on
            our Let’s Connect or write to us at{' '}
            <a href='mailto:contact@softobotics.com'>contact@softobotics.com</a>
          </div>
        </div>
      </Container>
      <Footer />
    </Wrapper>
  );
}

export default Privacypolicy;
